import Buttom from "./page/Buttom";
import { useEffect, useState } from "react";
import Mint from "./page/Mint";
import Invite from "./page/Invite";
import { ethers } from "ethers";
import "./i18n/config";
import Chain from "./chain/chain.json";
import relation_Abi from "./abi/Relation.json";
import Addr from "./addr/address.json";
import { message, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import "./App.css";
import Home from "./page/Home";
import Income from "./page/Income";
import { Route, Routes, useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { pageState } from "./store";
import Dapp from "./page/Dapp";
import Top from "./page/Top";

function App() {
  const [provider, setProvider] = useState(
    new ethers.providers.Web3Provider(window.ethereum)
  );
  const [address, setAddress] = useState();
  const setPage = useSetRecoilState(pageState);
  const [inviter, setInviter] = useState("");
  const location = useLocation();
  const relation_addr = Addr.Relation;
  function getInviter() {
    var reg = new RegExp("(^|&)inviter=([^&]*)(&|$)", "i");
    var r = window.location.search.slice(1).match(reg);

    if (r != null) {
      setInviter(decodeURIComponent(r[2]));
    } else {
      bindRelation();
    }
  }
  const bindRelation = async () => {
    new ethers.Contract(relation_addr, relation_Abi, provider)
      .defaultReferrer()
      .then((res) => {
        setInviter(res);
      });
  };

  const chosePage = () => {
    switch (location.pathname) {
      case "/":
        setPage(0);
        break;
      case "/income":
        setPage(1);
        break;
      case "/invite":
        setPage(2);
        break;
      case "/mint":
        setPage(3);
        break;
      default:
        setPage(4);
    }
  };

  const { t } = useTranslation();
  let currentAccount = null;
  useEffect(() => {
    document.title = t("title");
    setProvider(new ethers.providers.Web3Provider(window.ethereum));
    chosePage();
    getInviter();
    if (window.ethereum === undefined) {
      message.error({
        content: t("show1"),
      });
      // setShow(1)
    } else {
      connect();
      getChainId();
    }
  }, []);

  const connect = async () => {
    if (address !== "") {
      if (provider.provider.selectedAddress !== null) {
        setAddress(provider.provider.selectedAddress);
      } else {
        const accounts = await provider.send("eth_requestAccounts", []);

        setAddress(accounts[0]);
      }
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", function (_chain) {
        window.location.reload();
      });
    }
  };

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log("Please connect to MetaMask.");
    } else if (accounts[0] !== currentAccount) {
      currentAccount = accounts[0];
      connect(provider);
      setAddress(currentAccount);
      window.location.reload();
    }
  }

  const getChainId = async () => {
    //  Is it a primary link
    //  get chainId
    const chainId = window.ethereum.networkVersion;

    if (chainId !== Chain.testChain.chainId) {
      await window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(Chain.testChain.chainId * 1),
              chainName: Chain.testChain.chainName,
            },
          ],
        })
        .catch((error) => {
          console.log(error);
        });

      await window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexValue(Chain.testChain.chainId * 1),
            },
          ],
        })
        .then(() => {
          // handleSign();
          return;
        })
        .catch((e) => {
          console.log("wallet_switchEthereumChain error: ", e);
          return;
        })
        .finally(() => {});
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              activeBorderColor: "transparent",
              activeOutlineColor: "transparent",

              optionSelectedBg: "rgba(255,255,255,0.25)",
            },
            InputNumber: {
              colorText: "#fff",
              activeBg: "transparent",
              activeBorderColor: " #fff",
              hoverBg: "none",
              hoverBorderColor: "#fff",
            },
            Button: {
              defaultActiveBg: "linear-gradient(0deg,#0f98e2 0%,#1299e1 100% )",
              defaultActiveBorderColor: "transparent",
              defaultActiveColor: "transparent",
              defaultHoverBg: "linear-gradient(0deg,#0f98e2 0%,#1299e1 100% )",
              defaultHoverBorderColor: "transparent",
              defaultHoverColor: "#fff",
              defaultShadow: "none",
            },
          },
        }}
      >
        <Top provider={provider} />
        <Routes>
          <Route
            path="/"
            exact
            element={<Home provider={provider} inviter={inviter} />}
          />
          <Route path="/invite" element={<Invite provider={provider} />} />
          <Route
            path="/mint"
            element={<Mint provider={provider} inviter={inviter} />}
          />
          <Route path="/income" element={<Income provider={provider} />} />
          <Route path="/dapp" element={<Dapp provider={provider} />} />
        </Routes>

        <Buttom inviter={inviter} />
      </ConfigProvider>
      {/* {show===0?(<></>):(<Show msg='请安装MetaMask！' co={1} setShow={setShow}/>)} */}
    </>
  );
}

export default App;
