import styles from "./Buttom.module.css";
import cm from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { pageState } from "../store";
import { message } from "antd";
export default function Buttom({ inviter }) {
  const [msg, setMsg] = useState(false);
  const page = useRecoilValue(pageState);
  const setPage = useSetRecoilState(pageState);
  const { t } = useTranslation();
  const gotoIndex = () => {
    setPage(0);
  };
  const gotoNo = () => {
    // message.info(t("showmsg"));
    setPage(1);
  };
  const gotoTok = () => {
    setPage(2);
  };
  const gotoNft = () => {
    // setMsg(true);
    setPage(3);
  };
  const gotoCroa = () => {
    // message.info(t("showmsg"));
    setPage(4);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (msg) setMsg(false);
      clearTimeout(id);
    }, 3000);
  }, [msg]);

  return (
    <>
      <div className={styles.bu}>
        <div className={styles.bu1}>
          <div className={styles.busigle}></div>
          <div className={cm(styles.bu3, page === 0 ? "" : styles.op5)}>
            <Link to={`/?inviter=${inviter}`}>
              <div onClick={gotoIndex}>
                <div className={styles.bumg1}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.265677 7.69038L6.73517 2.02926L6.73533 2.02911C8.28128 0.676374 9.05426 0 10 0C10.9458 0 11.7188 0.676412 13.2647 2.02923L13.2648 2.02926L19.7343 7.69038C20.2891 8.17589 19.9171 9.03811 19.1529 9.03811C18.686 9.03811 18.3076 9.38767 18.3076 9.819V13.4229C18.3076 16.5233 18.3076 18.0736 17.2647 19.0368C16.4252 19.8122 15.1739 19.9634 12.9667 19.9929V17.6667C12.9667 17.1144 12.5189 16.6667 11.9667 16.6667H8.63332C8.08104 16.6667 7.63332 17.1144 7.63332 17.6667V19.9981C5.04762 19.985 3.64801 19.8798 2.73529 19.0368C1.69249 18.0736 1.69249 16.5233 1.69249 13.4229V9.819C1.69249 9.38767 1.31399 9.03811 0.847088 9.03811C0.082866 9.03811 -0.289112 8.17589 0.265677 7.69038Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className={styles.bufont0}>{t("bottom1")}</div>
              </div>
            </Link>
          </div>

          <div className={cm(styles.bu3, page === 2 ? "" : styles.op5)}>
            <Link to={`/invite?inviter=${inviter}`}>
              <div onClick={gotoTok}>
                <div className={styles.bumg1}>
                  <svg
                    width="29"
                    height="28"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.47509 16.6668C6.90312 17.6145 2.78151 19.5496 5.29185 21.9712C6.51813 23.154 7.88389 24 9.60098 24H19.399C21.1161 24 22.4819 23.154 23.7081 21.9712C26.2185 19.5496 22.0969 17.6145 20.5249 16.6668C16.8387 14.4444 12.1613 14.4444 8.47509 16.6668Z"
                      fill="#fff"
                    />
                    <path
                      d="M19.75 7.58337C19.75 10.4829 17.3995 12.8334 14.5 12.8334C11.6005 12.8334 9.25 10.4829 9.25 7.58337C9.25 4.68388 11.6005 2.33337 14.5 2.33337C17.3995 2.33337 19.75 4.68388 19.75 7.58337Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className={styles.bufont0}>{t("bottom3")}</div>
              </div>
            </Link>
          </div>
          <div className={styles.circle}>
            <img src="./img/component/shop.png" alt="" />
          </div>
          <div className={cm(styles.bu3, page === 1 ? "" : styles.op5)}>
            <Link to={`/income?inviter=${inviter}`}>
              <div onClick={gotoNo}>
                <div className={styles.bumg1}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11.6071 7.65962C11.2165 7.2691 10.5834 7.2691 10.1929 7.65962L7.52405 10.3284C7.13353 10.719 7.13353 11.3521 7.52405 11.7426L10.1929 14.4114C10.5834 14.802 11.2165 14.802 11.6071 14.4114L14.2759 11.7426C14.6664 11.3521 14.6664 10.719 14.2759 10.3284L11.6071 7.65962Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className={styles.bufont0}>{t("bottom2")}</div>
              </div>
            </Link>
          </div>
          {/* <div className={cm(styles.bu3,page===1?styles.op5:'')}>
            <Link to={`/mint?inviter=${inviter}`}>
              <div onClick={gotoNft}>
                <div className={page === 3 ? styles.bumg1 : styles.bumg0}>
                  <svg
                    width="29"
                    height="28"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9835 3.39587C13.8514 2.85205 12.0093 2.8708 10.8866 3.43807L5.00378 6.40799C3.88113 6.97525 3.8905 7.87771 5.02253 8.41685L11.3155 11.4126C12.4475 11.9517 14.3014 11.9517 15.4335 11.4102L21.5155 8.50592C22.6475 7.96444 22.6475 7.07839 21.5178 6.53457L14.9835 3.39587ZM10.7038 12.4557L5.04832 9.86313C3.92097 9.34744 2.99988 10.046 2.99988 11.4196V17.2094C2.99988 18.583 3.90222 20.1793 5.00378 20.7607L10.7483 23.7868C11.8499 24.3682 12.7522 23.7189 12.7522 22.3452V15.8967C12.7499 14.5231 11.8288 12.9737 10.7038 12.4557ZM7.87488 15.8217L7.44597 15.5967C6.33035 15.0107 5.42566 14.1153 5.43269 13.6042C5.43972 13.0932 6.35847 13.1542 7.47175 13.7402L7.90066 13.9652C9.01628 14.5512 9.92097 15.4467 9.91394 15.9577C9.90457 16.4687 8.9905 16.4054 7.87488 15.8217ZM16.2843 12.4111C15.1639 12.9503 14.2499 14.5161 14.2499 15.8897V22.3124C14.2499 23.686 15.1428 24.3142 16.235 23.7095L22.0147 20.5098C23.1069 19.9051 23.9999 18.2853 23.9999 16.9117V11.1852C23.9999 9.81156 23.0858 9.12944 21.9655 9.66857L16.2843 12.4111Z"
                      fill={page === 3 ? "#fff" : "#000"}
                    />
                  </svg>
                </div>
                <div className={page === 3 ? styles.bufont0 : styles.bufont}>
                  {t("bottom4")}
                </div>
              </div>
            </Link>
          </div> */}
          <div className={cm(styles.bu3, page === 4 ? "" : styles.op5)}>
            <Link to={`/dapp?inviter=${inviter}`}>
              <div onClick={gotoCroa}>
                <div className={styles.bumg1}>
                  <svg
                    width="29"
                    height="28"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0991 10.3H18.1451C18.2258 10.3 18.2955 10.3294 18.3541 10.388C18.4128 10.4467 18.4421 10.5164 18.4421 10.597V17.703C18.4421 17.7837 18.4128 17.8534 18.3541 17.912C18.2955 17.9707 18.2258 18 18.1451 18H16.3741C16.1981 18 16.0515 17.923 15.9341 17.769L13.5361 14.711V17.703C13.5361 17.7837 13.5068 17.8534 13.4481 17.912C13.3895 17.9707 13.3198 18 13.2391 18H11.1931C11.1125 18 11.0428 17.9707 10.9841 17.912C10.9255 17.8534 10.8961 17.7837 10.8961 17.703V10.597C10.8961 10.5164 10.9255 10.4467 10.9841 10.388C11.0428 10.3294 11.1125 10.3 11.1931 10.3H12.9751C13.1511 10.3 13.2941 10.3734 13.4041 10.52L15.8021 13.831V10.597C15.8021 10.5164 15.8315 10.4467 15.8901 10.388C15.9488 10.3294 16.0185 10.3 16.0991 10.3Z"
                      fill="#fff"
                    />
                    <path
                      d="M6.55405 7.1442H9.93389V5.6316C9.93389 4.18368 11.1028 3.00774 12.5498 3.00004C13.9557 2.99251 15.1015 4.12694 15.109 5.53381C15.1091 5.5569 15.1089 5.57999 15.1084 5.60307L15.0747 7.14418H18.3516C19.7622 7.14418 20.9056 8.28846 20.9056 9.69993V13.0841H22.482C23.8727 13.0841 25 14.2122 25 15.6038C25 16.9953 23.8727 18.1234 22.482 18.1234H20.9056V21.4443C20.9056 22.8557 19.7621 24 18.3516 24H15.1656V22.3951C15.1656 20.8838 13.9413 19.6587 12.431 19.6587C10.9207 19.6587 9.69633 20.8838 9.69633 22.3951V24H6.55405C5.1435 24 4 22.8557 4 21.4443V18.2082H5.77624C7.26761 18.2082 8.47658 16.9984 8.47658 15.5061C8.47658 14.0137 7.26761 12.8039 5.77626 12.8039H4V9.69995C4 8.28844 5.1435 7.1442 6.55405 7.1442Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className={styles.bufont0}>{t("bottom5")}</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {msg ? (
        <>
          <div className={styles.showmsg}>
            <div className={styles.showmsg1}>{t("showmsg")}</div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
