import styles from "./index.module.css";
import InvBar from "../component/Invbar";

import logic_Abi from "../../abi/MiningMachineLogic.json";
import mm_Abi from "../../abi/MiningMachine.json";
import erc20_Abi from "../../abi/erc20.json";
import mpl_Abi from "../../abi/MingPoolLogic.json";
import Addr from "../../addr/address.json";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
import { Button, message, InputNumber } from "antd";

import perSell_Abi from "../../abi/PreSellLP.json";
import { useTranslation } from "react-i18next";
export default function Home({ provider, inviter }) {
  const [machine, setMachine] = useState(0);
  const [dpos, setDpos] = useState(0);

  const [open, setOpen] = useState(false);
  const [loadings, setLoadings] = useState([false, false]);
  const address = useRecoilValue(addressState);
  const [showInput, setShowInput] = useState(false);

  const [isApproved, setIsApproved] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isApproveddp, setIsApproveddp] = useState(false);
  const [genesisBuy, setGenesisBuy] = useState([0, 0, 0]);
  const [dposBuy, setDposBuy] = useState([0, 0, 0]);
  const [isBuy, setIsBuy] = useState(false);
  const [computeNum, setComputeNum] = useState(0);
  const [perSellData, setPerSellData] = useState([0, 0]);
  const [rewardNum, setRewardNum] = useState(0);
  const [approveUsdtNum, setApproveUsdtNum] = useState(0);
  const [inputUsdtNum, setInputUsdtNum] = useState();

  const { t } = useTranslation();
  const logic_addr = Addr.MiningmachineLogic;
  const usdt_addr = Addr.usdt;
  const perSell_addr = Addr.PreSellLP;
  const mm_addr = Addr.MiningMachine;
  const mpl_addr = Addr.MingPoolLogic;
  const mm = new ethers.Contract(mm_addr, mm_Abi, provider);
  const logic = new ethers.Contract(logic_addr, logic_Abi, provider);
  const logicSign = new ethers.Contract(
    logic_addr,
    logic_Abi,
    provider.getSigner()
  );
  const perSell = new ethers.Contract(perSell_addr, perSell_Abi, provider);
  const perSellSign = new ethers.Contract(
    perSell_addr,
    perSell_Abi,
    provider.getSigner()
  );
  const usdt = new ethers.Contract(usdt_addr, erc20_Abi, provider);
  const usdtSign = new ethers.Contract(
    usdt_addr,
    erc20_Abi,
    provider.getSigner()
  );
  const mplSign = new ethers.Contract(mpl_addr, mpl_Abi, provider.getSigner());
  const iniDate = () => {
    perSell.getCurrentLpPower().then((res) => {
      setComputeNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    perSell.rewardTokenNum(address).then((res) => {
      setRewardNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    perSell.getPreSellLpData(address).then((res) => {
      setPerSellData([
        (ethers.utils.formatEther(res[0]) * 1).toFixed(4),
        (ethers.utils.formatEther(res[1]) * 1).toFixed(4),
      ]);
    });
    usdt.balanceOf(address).then((res) => {
      setBalance((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    // perSell.isJoined(address).then((res) => setIsBuy(res));
  };

  const approveUSDT = async (value) => {
    try {
      const num = ethers.utils.parseEther("" + value);
      const receipt = await (await usdtSign.approve(mpl_addr, num)).wait();
      if (receipt.status === 1) {
        message.success(t("approve") + t("success"));
        getApproveUSDTNum();
        setLoadings([false, loadings[1]]);
      }
    } catch (e) {
      message.error(t("approve" + t("fail")));
      message.error(e.reason || e.data?.message || e.message);
      setLoadings([false, loadings[1]]);
    }
  };

  // const preSell = async () => {
  //   setLoadings([true, loadings[1]]);
  //   try {
  //     if (!isApproved) {
  //       approveUSDT();
  //       return;
  //     }

  //     const receipt = await (await perSellSign.preSell(inviter)).wait();
  //     if (receipt.status === 1) {
  //       message.success(t("buy") + t("success"));
  //       setLoadings([false, loadings[1]]);
  //       window.location.reload();
  //     }
  //   } catch (e) {
  //     message.error(t("buy") + t("fail"));
  //     setLoadings([false, loadings[1]]);
  //     message.error(e.reason || e.data?.message || e.message);
  //   }
  // };

  const getApproveUSDTNum = () => {
    new ethers.Contract(usdt_addr, erc20_Abi, provider)
      .allowance(address, mpl_addr)
      .then((res) => {
        console.log(ethers.utils.formatEther(res));
        setApproveUsdtNum(ethers.utils.formatEther(res));
        if (ethers.utils.formatEther(res) >= inputUsdtNum) {
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
      });
  };
  const getNum = (value) => {
    if (value === null) return;
    setInputUsdtNum(parseInt(value));
    if (value > approveUsdtNum) {
      setIsApproved(false);
    } else {
      setIsApproved(true);
    }
  };
  const addlp = async () => {
    console.log(inputUsdtNum, inviter);
    if (inputUsdtNum === undefined || inputUsdtNum > balance * 1) {
      return;
    }
    setLoadings([true, loadings[1]]);
    if (!isApproved) {
      approveUSDT(inputUsdtNum);
    } else {
      try {
        const receipt = await (
          await mplSign.addLiquidity(inputUsdtNum, inviter)
        ).wait();
        if (receipt.status === 1) {
          message.success(t("add") + t("success"));
          setLoadings([false, loadings[1]]);
          window.location.reload();
        }
      } catch (e) {
        message.error(t("add") + t("fail"));
        setLoadings([false, loadings[1]]);
        message.error(e.reason || e.data?.message || e.message);
      }
    }
  };
  useEffect(() => {
    if (address === "") return;
    iniDate();
    getApproveUSDTNum();
  }, [address]);
  return (
    <>
      <div className={"home"}>
        {/* {showInput && <InputNum setShowInput={setShowInput} />} */}

        <div className={styles.hometop}></div>
        <div className={styles.burn0}></div>
        <div className={styles.mtinv}>
          <InvBar inviter={inviter} provider={provider} />
        </div>
        <div className={styles.burn1}></div>
        <div className={styles.burn2}></div>
        <div className={styles.body}>
          {/* <div className="w-full">
            <div className={styles.bodytitle}>{t("presalelp")}</div>
            <div className={styles.bodybar}>
              <div className={styles.lptop}>
                <div className={styles.center}>
                  <img src="/img/home/usdt.png" alt="usdt" />
                  <div className={cm(styles.add, styles.center)}>+</div>
                  <div className={cm(styles.m, styles.center)}>M</div>
                </div>
                <div className="text-right">
                  <div className={styles.font1}>{t("nowlpcompute")}</div>
                  <div className={cm(styles.font2)}>{computeNum}</div>
                </div>
              </div>

              <div className={styles.inputbar}>
                <div className={styles.input}>
                  <div className={styles.innum}> 500.0000</div>
                  <Suffix />
                </div>
                <div className={cm(styles.info, styles.font1)}>
                  {t("balance")}: <span>{balance} U</span>
                </div>
              </div>
              <div className={styles.btnbar}>
                <Button
                  className="homebtnbuy"
                  loading={loadings[0]}
                  disabled={isBuy}
                  onClick={preSell}
                >
                  {isBuy ? t("hasbuy") : isApproved ? t("buy") : t("approve")}
                </Button>
                <div className={cm(styles.info, "center")}>{t("tips")}</div>
              </div>
              <div className={styles.burn}></div>
            </div>
            <div className></div>
          </div> */}

          <div className={styles.top}>
            <div>
              <img src="/img/拼图.png" alt="dapp" loading="lazy" />
              LP
            </div>

            <div className={styles.topbar}>
              <div className={styles.title}>
                <img src="/img/添加.png" alt="dapp" loading="lazy" />
                <span>{t("add")}LP</span>
              </div>
              <div>
                <div className={styles.inputbar}>
                  <span>USDT{t("number")}</span>
                  <InputNumber
                    className={styles.input}
                    placeholder={t("placeholder")}
                    min={100}
                    step={1}
                    value={inputUsdtNum}
                    onChange={getNum}
                  />
                </div>
                <div className={styles.balance}>
                  <span>{t("balance")}:</span>
                  <span>{balance}U</span>
                </div>
              </div>
              <Button
                className="homebtnbuy"
                onClick={addlp}
                loading={loadings[0]}
              >
                {isApproved ? <>{t("add")}LP</> : <>{t("approve")}LP</>}
              </Button>
              <div className={styles.tips}>{t("tip")}</div>
              <div className={styles.burn}></div>
              <div className={styles.burn4}></div>
            </div>
          </div>

          <div className="w-full">
            <div className={styles.bodytitle}>{t("presalelpdata")}</div>
            <div className={styles.bodybar}>
              <div className={styles.lpbar}>
                <div>{t("presalelptotal")}</div>
                <div>{perSellData[0]}U</div>
              </div>
              {/* <div className={styles.lpdata}>
                <div>预售LP总算力</div>
                <div className="red">1400</div>
              </div> */}
              <div className={styles.lpdata}>
                <div>{t("myprelpcompute")}</div>
                <div>{perSellData[1]}</div>
              </div>
            </div>
          </div>
          <div className={styles.burn}></div>
          <div className={styles.burn4}></div>
          <div className="w-full">
            <div className={styles.bodytitle}>{t("presalelpdata")}</div>
            <div className={styles.bodybar}>
              <div className={styles.lpbar}>
                <div>{t("directpushreward")}</div>
                <div className="white">{rewardNum}MM</div>
              </div>
              <div className="px-16">
                {open ? (
                  <Button className="homebtnbuy">{t("receive")}</Button>
                ) : (
                  <div className={styles.waitebtn}>{t("awaitopen")}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Suffix = () => {
  return (
    <div className={styles.suffix}>
      <img
        src="/img/home/usdt.png"
        alt="usdt"
        style={{ width: "20px", height: "20px", marginRight: "4px" }}
      />
      <div>USDT</div>
    </div>
  );
};
