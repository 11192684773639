import styles from "./index.module.css";
import cm from "classnames";
import { Button, message } from "antd";
import { useEffect, useState } from "react";
import miningMachine_Abi from "../../abi/MiningMachine.json";
import miningPool_Abi from "../../abi/MiningPool.json";
import Addr from "../../addr/address.json";
import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
import { ethers } from "ethers";
import { t } from "i18next";
function Income({ provider }) {
  const [showOp, setShowOp] = useState(0);

  const miningMachine_addr = Addr.MiningMachine;
  const miningPool_addr = Addr.MingPool;
  const address = useRecoilValue(addressState);
  const [users, setUsers] = useState({
    dynamicPowers: 0,
    hasTookAmount: 0,
    lpNum: 0,
    rewardDebt: 0,
    rewardDebtDynamics: 0,
    stakeAmount: 0,
    unlockTime: 0,
  });

  const [loadings, setLoadings] = useState([false, false]);
  const [totalStatic, setTotalStatic] = useState(0);
  const [totalDynamic, setTotalDynamic] = useState(0);
  const [pendingDynamic, setPendingDynamic] = useState(0);
  const [pendingStatic, setPendingStatic] = useState(0);
  const [pendtotal, setPendTotal] = useState([]);
  const [canTake, setCanTake] = useState(false);

  const miningPool = new ethers.Contract(
    miningPool_addr,
    miningPool_Abi,
    provider
  );
  const miningPoolSign = new ethers.Contract(
    miningPool_addr,
    miningPool_Abi,
    provider.getSigner()
  );
  const initData = async () => {
    miningPool.userInfo(address).then((res) => {
      setUsers({
        lpNum: (ethers.utils.formatEther(res.lpNum) * 1).toFixed(4),
        unlockTime: parseInt(res.unlockTime),
        stakeAmount: (ethers.utils.formatEther(res.stakeAmount) * 1).toFixed(4),
        dynamicPowers: (
          ethers.utils.formatEther(res.dynamicPowers) * 1
        ).toFixed(4),
      });
    });
    miningPool.totalStaticPowers().then((res) => {
      setTotalStatic((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    miningPool.totalDynamicPowers().then((res) => {
      setTotalDynamic((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    miningPool.pending(address).then((res) => {
      setPendingStatic((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    miningPool.pendingD(address).then((res) => {
      setPendingDynamic((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    miningPool.canTakeLiquidity(address).then((res) => {
      setCanTake(res);
    });
    miningPool.pendTotalRelease(address).then((res) => {
      setPendTotal([
        (ethers.utils.formatEther(res[0]) * 1).toFixed(4),
        (ethers.utils.formatEther(res[1]) * 1).toFixed(4),
      ]);
    });
  };

  const release = async () => {
    try {
      setLoadings([true, loadings[1]]);
      const receipt = await (await miningPoolSign.release()).wait();
      if (receipt.status === 1) {
        message.success(t("extract" + t("success")));
        setLoadings([false, loadings[1]]);
        window.location.reload();
      }
    } catch (e) {
      message.error(t("extract" + t("fail")));
      message.error(e.reason || e.data?.message || e.message);
      setLoadings([false, loadings[1]]);
    }
  };

  const leave = async () => {
    if (!canTake) {
      message.info(t("info"));
      return;
    }
    try {
      setLoadings([loadings[0], true]);
      const receipt = await (await miningPoolSign.leave()).wait();
      if (receipt.status === 1) {
        message.success(t("extract" + t("success")));
        setLoadings([loadings[0], false]);
        window.location.reload();
      }
    } catch (e) {
      message.error(t("extract" + t("fail")));
      message.error(e.reason || e.data?.message || e.message);
      setLoadings([loadings[0], false]);
    }
  };

  useEffect(() => {
    // preloadImg();
    if (address === "") return;
    initData();
  }, [address]);
  return (
    <>
      <div className={"home"}>
        <div className={styles.incometop}>
          <div className={styles.burn0}></div>
          <div className={styles.title}>{t("lpcomputedata")}</div>
          <div className={styles.infos}>
            <div className={cm(styles.center, styles.pl)}>
              <img src="/img/home/usdt.png" alt="usdt" />
              <div className={cm(styles.add, styles.center)}>+</div>
              <div className={cm(styles.m, styles.center)}>M</div>
              <span>MM-USDT &nbsp;LP</span>
            </div>
            <div className={styles.info}>
              <span>{t("mystatic")}</span>
              <div className={cm("flex", styles.pl)}>
                <div className={styles.infodetail}>{t("lpcompute")}</div>
                <div className={styles.infodetail}>{users.stakeAmount}</div>
              </div>
              <span>{t("mydynamic")}</span>
              <div className={cm("flex", styles.pl)}>
                <div className={styles.infodetail}>{t("reclpconpute")}</div>
                <div className={styles.infodetail}>{users.dynamicPowers}</div>
              </div>
              <div className={styles.infobar}>
                <div className={styles.bar}>
                  <div>
                    <div>{totalStatic}</div>
                    <div>{t("netstatic")}</div>
                  </div>
                  <div>
                    <div>{totalDynamic}</div>
                    <div>{t("netdynamic")}</div>
                  </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.bar}>
                  <div>
                    <div>{t("totalincome")}</div>
                    {/* <div>{pendtotal[0]}B</div>
                    <div>{t("staticincome")}</div> */}
                  </div>
                  <div>
                    <div>{pendtotal[0]}B</div>
                    <div>{pendtotal[1]}U</div>
                  </div>
                </div>
              </div>
              <div className={styles.mx}>
                <Button
                  className={cm("homebtnbuy")}
                  loading={loadings[0]}
                  onClick={release}
                >
                  {t("extract")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.burn}></div>
        <div className={styles.burn3}></div>
        <div className={styles.burn4}></div>
        <div className={styles.incomebody}>
          <div className={styles.title}>{t("my")}LP</div>
          <div className={styles.details}>
            <div className={styles.barn}>
              <div>LP{t("number")}</div>
              <div>{users.lpNum}LP</div>
            </div>
            <div className={styles.barn}>
              <div>LP{t("capital")}</div>
              <div>{users.stakeAmount}U</div>
            </div>
            <div className={styles.barn}>
              <div>{t("deadline")}</div>
              <div>{users.unlockTime}</div>
            </div>
            <div className={styles.barn}>
              <div>{t("term")}</div>
              <div>7{t("day")}</div>
            </div>

            <Button
              className={cm("homebtnbuy")}
              loading={loadings[1]}
              onClick={leave}
            >
              {t("extract")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default Income;
