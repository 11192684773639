import styles from "./index.module.css";
import Top from "../Top";
import { Button, Input, InputNumber, message } from "antd";
import Addr from "../../addr/address.json";
import erc20_Abi from "../../abi/erc20.json";
import lp_Abi from "../../abi/LPStaking.json";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
const Mint = ({ provider, inviter }) => {
  const [mintvalue, setMintValue] = useState(0);

  const [btn, setBtn] = useState([]);
  const [approve, setApprove] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isApprove, setIsApprove] = useState(false);
  const [pendingNum, setPendingNum] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const address = useRecoilValue(addressState);
  const node_addr = Addr.NodeList;
  const usdt_addr = Addr.usdt;
  const lpStaking_addr = Addr.LPStaking;
  const lp_addr = Addr["AKTPair(lp)"];
  const lp = new ethers.Contract(lp_addr, erc20_Abi, provider);
  const lpsign = new ethers.Contract(lp_addr, erc20_Abi, provider.getSigner());

  const lprewardSign = new ethers.Contract(
    lpStaking_addr,
    lp_Abi,
    provider.getSigner()
  );
  const lpreward = new ethers.Contract(lpStaking_addr, lp_Abi, provider);
  const initData = () => {
    lp.balanceOf(address).then((res) => {
      setBalance((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    lpreward
      .pending(address)
      .then((res) =>
        setPendingNum((ethers.utils.formatEther(res) * 1).toFixed(4))
      );
    lpreward.userInfo(address).then((res) => {
      setUserInfo({
        stakeAmount: (ethers.utils.formatEther(res.stakeAmount) * 1).toFixed(4),
        lastWithdrawTime: (
          ethers.utils.formatEther(res.lastWithdrawTime) * 1
        ).toFixed(4),
        rewardDebt: (ethers.utils.formatEther(res.rewardDebt) * 1).toFixed(4),
        unlockTime: (ethers.utils.formatEther(res.unlockTime) * 1).toFixed(4),
      });
    });
    lpreward.isActive(address).then((res) => setIsActive(res));
  };
  const approveLP = () => {
    lp.allowance(address, lpStaking_addr).then((res) => {
      const approvenum = ethers.utils.formatEther(res);
      setApprove(approvenum);
      if (approvenum >= mintvalue) {
        setIsApprove(true);
      } else {
        setIsApprove(false);
      }
    });
  };

  const deposit = async () => {
    if (pendingNum * 1 <= 0) {
      message.info("暂无收益");
      return;
    }

    setBtn([btn[0], true, btn[2], btn[3]]);
    try {
      const receipt = await (await lprewardSign.deposit(0)).wait();
      if (receipt.status === 1) {
        message.success("提取收益成功");
        setBtn([btn[0], false, btn[2], btn[3]]);
        window.location.reload();
      }
    } catch (e) {
      message.error("提取收益失败");
      setBtn([btn[0], false, btn[2], btn[3]]);
      message.error(e.reason || e.data?.message || e.message);
    }
  };

  const getMintvalue = (value) => {
    if (value <= 0) {
      value = 0;
      return;
    }
    // if (value > 2) {
    //   e.target.value = 2;
    //   setMintValue(2);
    //   return;
    // }
    // const len = getPoint(value);
    const num = value * 1;
    // if (len > 1) {
    //   e.target.value = num;
    // }
    if (approve >= num) {
      setIsApprove(true);
    } else {
      setIsApprove(false);
    }
    setMintValue(num);
  };

  const getPoint = (num) => {
    const numStr = num.toString();
    const parts = numStr.split(".");
    if (parts.length === 1) {
      return 0;
    } else {
      return parts[1].length;
    }
  };

  const mint = async () => {
    if (mintvalue === 0) {
      message.info("输入的数量要大于等于余额");
      return;
    }
    setBtn([true, btn[1], btn[2], btn[3]]);
    let defaultNum = ethers.utils.parseEther(mintvalue + "");
    if (!isApprove) {
      try {
        const receipt = await (
          await lpsign.approve(lpStaking_addr, defaultNum)
        ).wait();
        if (receipt.status === 1) {
          message.success("授权成功");
          approveLP();
          setBtn([false, btn[1], btn[2], btn[3]]);
        }
      } catch (e) {
        message.error("授权失败");
        setBtn([false, btn[1], btn[2], btn[3]]);
        message.error(e.reason || e.data?.message || e.message);
      }
    } else {
      try {
        const res = await lprewardSign.deposit(defaultNum);

        const po = await res.wait();
        if (po.status === 1) {
          message.success("质押成功");
          setBtn([false, btn[1], btn[2], btn[3]]);
          window.location.reload();
        }
      } catch (callError) {
        setBtn([false, btn[1], btn[2], btn[3]]);
        message.error("质押失败");
        message.error(
          callError.reason || callError.data?.message || callError.message
        );
      }
    }
  };

  const leave = async () => {
    setBtn([btn[0], btn[1], btn[2], true]);
    try {
      const receipt = await (await lprewardSign.leave()).wait();
      if (receipt.status === 1) {
        message.success("解压成功");
        setBtn([btn[0], btn[1], btn[2], false]);
        window.location.reload();
      }
    } catch (e) {
      message.error("解压失败");
      setBtn([btn[0], btn[1], btn[2], false]);
      message.error(e.reason || e.data?.message || e.message);
    }
  };
  const todapp = () => {
    window.location.href = "/dapp?inviter=" + inviter;
  };
  useEffect(() => {
    if (address === "") return;
    initData();
    approveLP();
  }, [address]);
  return (
    <>
      <div className="home">
        <img
          src="./img/home/homebg.png"
          className="homebg"
          alt=""
          loading="lazy"
        />
        <Top provider={provider} />

        <img
          src="./img/mint/mintbg1.png"
          alt=""
          loading="lazy"
          className={styles.mintbg}
        />
        <div className={styles.minttopbg}>
          <div className={styles.mintfont0}>LP质押</div>
          <div className={styles.g4}>
            <InputNumber
              className={styles.mintInput}
              placeholder="请输入LP数量"
              suffix="质押数量"
              maxLength="10"
              type="number"
              controls={false}
              onChange={(e) => getMintvalue(e)}
              min={0}
              max={balance}
            />
            <div className={styles.font2}>余额: {balance}LP</div>
          </div>
          <Button className="homebtnbuy" loading={btn[0]} onClick={mint}>
            {isApprove ? "质押" : "授权"}
          </Button>
        </div>

        <div className={styles.mintbodybg}>
          <div className={styles.mintbodyt}>
            <img src="./img/mint/mint.png" alt="" />
            质押记录
          </div>
          <img src="./img/mint/mintbg0.png" alt="" />
          <div className={styles.mintbody}>
            <div>LP</div>
            <div className={styles.g4}>
              <div
                className={styles.font}
                style={{ color: "rgba(255,255,255,0.6)" }}
              >
                质押LP数量
              </div>
              <div>{userInfo.stakeAmount}</div>
            </div>
            <div className={styles.between}>
              <div className={styles.g4}>
                <div
                  className={styles.font}
                  style={{ color: "rgba(255,255,255,0.6)" }}
                >
                  LP分红收益(USDT)
                </div>
                <div>{pendingNum}</div>
              </div>
              <Button className={styles.btn} loading={btn[1]} onClick={deposit}>
                提取收益
              </Button>
            </div>
            <div className={styles.between}>
              <div>分红资格</div>
              {!isActive ? (
                <div
                  className={styles.btn}
                  style={{ background: "#ffe800" }}
                  onClick={todapp}
                >
                  未激活
                </div>
              ) : (
                <div
                  className={styles.btn}
                  style={{ background: " rgba(98, 98, 98, 1)" }}
                >
                  已激活
                </div>
              )}
            </div>
            <Button className="homebtnbuy" loading={btn[3]} onClick={leave}>
              解压
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mint;
