import { useEffect, useState } from "react";
import Top from "../Top";
import erc20_Abi from "../../abi/erc20.json";
import swap_Abi from "../../abi/SwapForActive.json";
import Addr from "../../addr/address.json";
import cm from "classnames";
import styles from "./index.module.css";
import nodeMing_Abi from "../../abi/NodeMingPool.json";
import { Button, InputNumber, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import Carousel from "../component/Carousel";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { addressState } from "../../store";

export default function Dapp({ provider }) {
  let times = "Sat Mar 31 2024 13:00:00 GMT+0800";
  const { t } = useTranslation();
  const [usdtNum, setUsdtNum] = useState(0);
  const [aktNum, setAktNum] = useState(0);
  const address = useRecoilValue(addressState);
  const [calculateNum, setCalculateNum] = useState(0);
  const [btn, setBtn] = useState(false);
  const [nodeLevel, setNodeLevel] = useState(0);
  const [isApproved, setIsApproved] = useState(false);

  const nodeMing_addr = Addr.NodeMingPool;

  const [level, setLevel] = useState(0);
  const [pending, setPending] = useState(0);
  const [canLevelUp, setCanLevelUp] = useState(false);
  const [isNode, setIsNode] = useState(false);
  const [canBeNode, setCanBeNode] = useState(false);
  const nodeMing = new ethers.Contract(nodeMing_addr, nodeMing_Abi, provider);
  const nodeMingSign = new ethers.Contract(
    nodeMing_addr,
    nodeMing_Abi,
    provider.getSigner()
  );
  const [loadings, setLoadings] = useState([false, false]);
  const [users, setUsers] = useState({
    dynamicPowers: 0,
    hasTookAmount: 0,
    lpNum: 0,
    rewardDebt: 0,
    rewardDebtDynamics: 0,
    stakeAmount: 0,
    unlockTime: 0,
  });

  const initData = () => {
    nodeMing.determineNodeLevel(address).then((res) => {
      setNodeLevel(parseInt(res));
    });
    nodeMing.userInfo(address).then((res) => {
      setLevel(parseInt(res.level));
    });
    nodeMing.pending(address).then((res) => {
      setPending((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    nodeMing.canLevelUp(address).then((res) => {
      setCanLevelUp(res);
    });
    nodeMing.isNode(address).then((res) => {
      setIsNode(res);
    });
    nodeMing.canToBeNode(address).then((res) => {
      setCanBeNode(res);
    });
  };

  const applyToBeNode = async () => {
    try {
      setLoadings([loadings[0], true]);
      const receipt = await (await nodeMingSign.toBeNode()).wait();
      if (receipt.status === 1) {
        message.success(t("benode") + t("success"));
        setLoadings([loadings[0], false]);
        window.location.reload();
      }
    } catch (callError) {
      setLoadings([loadings[0], false]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
      console.log(callError);
      message.error(t("benode") + t("fail"));
    }
  };

  const levelUp = async () => {
    try {
      setLoadings([true, loadings[1]]);
      const receipt = await (await nodeMingSign.levelUp()).wait();
      if (receipt.status === 1) {
        message.success(t("upnode") + t("success"));
        setLoadings([false, loadings[1]]);
        window.location.reload();
      }
    } catch (callError) {
      setLoadings([false, loadings[1]]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
      message.error(t("upnode") + t("fail"));
    }
  };
  const withdraw = async () => {
    setLoadings([loadings[0], true]);
    try {
      const receipt = await (await nodeMingSign.withdraw()).wait();
      if (receipt.status === 1) {
        message.success(t("withdraw") + t("success"));
        setLoadings([loadings[0], false]);
        window.location.reload();
      }
    } catch (callError) {
      setLoadings([loadings[0], false]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
      message.error(t("withdraw") + t("fail"));
    }
  };

  useEffect(() => {
    if (address === "") return;
    initData();
    // approveUSDT();
    // calculateAKTCanSwap();
  }, [address]);

  return (
    <>
      <div className="home">
        <div className={styles.invitetop}>
          <img src="./img/invite/invite.png" alt="top" />
        </div>
        <div className={styles.burn0}></div>
        <div className={styles.incomebody}>
          <div className={styles.title}>{t("mynode")}</div>
          <div className={styles.details}>
            <div className={styles.barn}>
              <div>{t("levelnow")}</div>
              <div>
                {(nodeLevel === 0
                  ? t("invalid")
                  : nodeLevel === 1
                  ? t("primary")
                  : nodeLevel === 2
                  ? t("middle")
                  : nodeLevel === 3
                  ? t("senior")
                  : t("supper")) + t("node")}
              </div>
            </div>
            <div className={styles.barn}>
              <div>{t("dividendlevel")}</div>
              <div>
                {(level === 0
                  ? t("invalid")
                  : level === 1
                  ? t("primary")
                  : level === 2
                  ? t("middle")
                  : level === 3
                  ? t("senior")
                  : t("supper")) + t("node")}
              </div>
            </div>
            <div className={styles.barn}>
              <div>{t("nodedevidend")}</div>
              <div>{pending} MM</div>
            </div>
            {canLevelUp && (
              <div className={styles.barn}>
                <div>{t("iscanup")}</div>
                <div>
                  <Button
                    className={styles.upbtn}
                    loading={loadings[0]}
                    onClick={levelUp}
                  >
                    {t("canup")}
                  </Button>
                </div>
              </div>
            )}
            {isNode ? (
              <Button
                className={"homebtnbuy"}
                loading={loadings[1]}
                onClick={withdraw}
              >
                {t("withdraw")}
              </Button>
            ) : (
              <>
                {canBeNode && (
                  <Button
                    className={"homebtnbuy"}
                    loading={loadings[1]}
                    onClick={applyToBeNode}
                  >
                    {t("tobenode")}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.burn3}></div>
      <div className={styles.burn4}></div>
    </>
  );
}
